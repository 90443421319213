import PropTypes from 'prop-types'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Button from '../containers/Button'
import TextInput from './TextInput'


const PasswordInput = ({name, label, disabled, required, ...props}) => {
  const {t} = useTranslation()
  const [show, setShow] = useState(false)

  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const input = e.target?.closest('div')?.previousElementSibling
    setShow((prevShow) => !prevShow)
    if (input) input.focus()
  }
  const switcher = (
    <Button
        variant="plain"
        size="short"
        color="textSecondary"
        iconName={show ? 'visibility_off' : 'visibility'}
        onClick={handleClick}
        hideText
        type="button"
    >
      {t('PasswordInput.show')}
    </Button>
  )
  return (
    <TextInput
        name={name}
        label={label}
        disabled={disabled}
        required={required}
        autoComplete="current-password"
        {...props}
        after={switcher}
        placeholder="***********"
        type={show ? 'text' : 'password'}
    />
  )
}

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}

export default PasswordInput
