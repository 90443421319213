import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {LongTextInput, Input, FormComponent} from '../../../uikit'
import {showErrorOnChange} from '../../utils/forms'
import {useFormCtx} from './withForm'


const TextInput = ({
  name, label, required, helperText, fieldProps, showError = showErrorOnChange, type, multiline, ...props
}) => {
  const ctx = useFormCtx()
  const {input: {value, onChange, ...restInput}, meta} = useField(name, {type, ...fieldProps})
  const {isError, helperTextOrError} = showError({meta, helperText})

  const Component = multiline ? LongTextInput : Input
  return (
    <FormComponent invalid={isError} label={label} helperText={helperTextOrError} required={required}>
      <Component
          value={value}
          disabled={ctx.disabled}
          invalid={isError}
          {...restInput}
          onChange={onChange}
          required={required}
          {...props}
      />
    </FormComponent>
  )
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'email', 'password', 'date', 'time']),
  multiline: PropTypes.bool,
}

export default TextInput
