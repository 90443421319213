/**
 * Values for request validation. RegExps adopted for JS
 * Based on grason-api:src/Grason/Common/Validation/Model/Pattern.php
 */

export const CODE_LENGTH = 4
export const CIN_LENGTH = 8
export const ZIP_LENGTH = 5
export const PHONE_LENGTH = 9

export const COUNTRY_CODE = /^\+[\d]{3}$/
export const PHONE = new RegExp(`^[0-9]{${PHONE_LENGTH}}$`)
export const VERIFICATION_CODE = new RegExp(`^[0-9]{${CODE_LENGTH}}$`)
export const SOME = /.+/
export const ANY = /.*/
export const NON_NUMERIC_WORD = /^[^0-9]+$/
export const DATE = /^[\d]{4}-[\d]{2}-[\d]{2}$/
export const TIME = /^[\d]{2}:[\d]{2}$/
export const DATE_TIME = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/
export const INTEGER = /^[\d]+$/
export const POSITIVE_INTEGER = /^[1-9]\d*$/
export const MONEY = /^[\d]+(\.[\d]{0,2})?$/
export const RATING = /^(5(\.0)?|([1-4]{1}(\.[0-9]{1})?))$/
export const COUNTRY = /^[a-z]{2}$/
export const CIN = new RegExp(`^[0-9]{${CIN_LENGTH}}$`)
export const ZIP = new RegExp(`^[0-9]{${ZIP_LENGTH}}$`)
export const HEX_COLOR = /^[a-fA-Z0-9]{6}$/
export const UUID = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/

/* Custom patterns */

// eslint-disable-next-line max-len
export const EMAIL = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"(\[]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/
