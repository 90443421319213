import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import {px2rem} from '../theme/theme'
import * as typography from '../typography'
import {fieldPropTypes} from './common'


const StyledInput = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  min-height: ${px2rem(40)};
  padding: ${({theme}) => theme.spacer};
  background: ${({theme}) => theme.colors.background1};
  border: ${({theme}) => theme.border.size} solid ${({theme}) => theme.colors.background1};
  border-radius: ${({theme}) => theme.border.radius};
  resize: vertical;
  color: ${({theme}) => theme.colors.text};
  ${typography.input}

  &:focus-within {
    border-color: ${({theme}) => theme.colors.primary};
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    color: ${({theme}) => theme.colors.textSecondary};
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.textMuted};
  }

  ${({theme, invalid}) => invalid && css`
    background-color: ${theme.colors.negative2};
    border-color: ${theme.colors.negative};

    &::placeholder {
      color: transparent;
    }
  `}
`


const LongTextInput = ({name, value, onChange, onBlur, onFocus, disabled, invalid, required, ...props}) => {
  return (
    <StyledInput
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        invalid={invalid}
        required={required}
        {...props}
    />
  )
}

LongTextInput.defaultProps = {

}

LongTextInput.propTypes = {
  ...fieldPropTypes,
  placeholder: PropTypes.string,
}

export default LongTextInput
